@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
.home-bg-img {
  background-image: url('../../../images/home_tessellation.png');
  background-size: 100px;
  filter: brightness(26%);
  /* General attributes */
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  height: 100%;
  z-index: -1;
}

.home-container {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-container {
  display: flex;
  flex-wrap: wrap;
}

.post-hero-container {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.article-centered-home {
  width: calc(100vw - 300px);
}

.first-box-margin {
  margin-top: 30px;
}

.hero-no-img {
  height: 50vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
}

.hero-img {
  position: relative;
  height: 50vw;
  width: 100vw;
  overflow: hidden;
}

.hero-img-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(231, 117, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(3, 162, 220, 0.6));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-animation: fade 48s infinite, grow 24s infinite;
  animation: fade 48s infinite, grow 24s infinite;
  -webkit-animation-timing-function: ease, linear;
  animation-timing-function: ease, linear;
}

#hero-img-1 {
  background-image: linear-gradient(rgba(231, 117, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(3, 162, 220, 0.6)),
    url(../../../images/home/droid-2.jpg);
  animation-delay: 0s, -2s;
}

#hero-img-2 {
  background-image: linear-gradient(rgba(231, 117, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(3, 162, 220, 0.6)),
    url(../../../images/home/droid-1.jpg);
  animation-delay: 8s, 6s;
}

#hero-img-3 {
  background-image: linear-gradient(rgba(231, 117, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(3, 162, 220, 0.6)),
    url(../../../images/home/pacbot-2.jpg);
  animation-delay: 16s, 14s;
}

#hero-img-4 {
  background-image: linear-gradient(rgba(231, 117, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(3, 162, 220, 0.6)),
    url(../../../images/home/pacbot-4.jpg);
  animation-delay: 24s, 22s;
}

#hero-img-5 {
  background-image: linear-gradient(rgba(231, 117, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(3, 162, 220, 0.6)),
    url(../../../images/home/drone-2.jpg);
  animation-delay: 32s, 30s;
}

#hero-img-6 {
  background-image: linear-gradient(rgba(231, 117, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(3, 162, 220, 0.6)),
    url(../../../images/home/drone-1.jpg);
  animation-delay: 40s, 38s;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  12.5% {
    opacity: 1;
  }
  16.7% {
    opacity: 0;
  }
  95.8% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  12.5% {
    opacity: 1;
  }
  16.7% {
    opacity: 0;
  }
  95.8% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.hero-logo {
  height: 150vw;
  width: 150vw;
  opacity: 25%;
  background-image: url(../../../images/home/PURC\ Logo\ Blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
}

.hero-text {
  padding: 1vw 2vw 1vw 2vw;
  font-family: 'Century Gothic';
  font-family: 'Roboto Mono', monospace;
  font-size: 4vw;
  color: black;
  background-color: white;
  /* text-transform: uppercase; */
  z-index: 1;
}

.hero-word {
  font-family: 'Roboto Mono', 'Courier New', monospace;
  /* text-transform: lowercase; */
  font-size: 5vw;
  color: rgb(231, 117, 0);
}

.hero-bar {
  font-size: 5vw;
  color: rgba(0, 0, 0, 0);
}

.hero-subtext {
  padding: 1vw 2vw 1vw 2vw;
  font-family: 'Century Gothic';
  font-size: 3vw;
  color: white;
  text-transform: uppercase;
  z-index: 1;
  font-weight: bold;
  text-align: center;
}

.text-droid {
  color: rgb(227, 77, 0);
}

.text-pacbot {
  color: rgb(255, 183, 0);
}

.text-golf {
  color: rgb(48, 191, 134);
}

.text-drone {
  color: rgb(0, 126, 255);
}

.text-exo {
  color: rgb(170, 48, 191);
}

.text-rover {
  color: rgb(191, 48, 103);
}

.text-robocup {
  color: rgb(58, 185, 169);
}

.text-tinker {
  color: rgb(181, 27, 20);
}

.text-wall-e {
  color: rgb(244, 183, 0);
}

.text-bionics {
  color: rgb(222, 64, 199);
}

/* ------------------------------------------------------------------------- */
/*                                  MOBILE                                   */
/* ------------------------------------------------------------------------- */

@media screen and (max-width: 960px) {
  .article-centered-home {
    width: calc(100vw - 50px);
  }

  .hero-no-img {
    height: 150vw;
  }

  .hero-img {
    height: 150vw;
  }

  .hero-logo {
    height: 300vw;
    width: 300vw;
  }

  .hero-text {
    padding: 2vw 4vw 2vw 4vw;
    font-size: 8vw;
  }

  .hero-word {
    font-size: 8.5vw;
  }

  .hero-subtext {
    padding: 2vw 4vw 2vw 4vw;
    font-size: 6vw;
  }
}
