.search {
  width: 80%;
  outline: none;
  transition: 300ms ease-in;
  height: 40px;
  font-size: 1.3rem;
  padding: 5px 22px;
  border-radius: 10px;
  font-family: 'Century Gothic';
  text-align: center;
  background-color: transparent;
}

.search-light {
  border: 1px solid black;
  color: black;
}

.search-dark {
  border: 1px solid white;
  color: white;
}

.search:focus {
  border-color: coral;
}
.search::placeholder {
  color: gray;
}
