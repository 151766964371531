.face-card {
  height: -moz-max-content;
  height: fit-content;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 10px;
  width: 200px;
  min-height: 250px;
  margin-top: 0px;
  border: 2px solid;
  border-radius: 10px;
  line-height: 25px;
  display: block;
  float: left;
  margin-bottom: 30px;
  overflow: auto;
  filter: opacity(0.8);
  transition: 0.4s;
}

.face-card:hover {
  filter: opacity(1);
}

.face-card-dark:hover {
  border-color: orange;
  transition: 0.4s;
}

.face-card-light:hover {
  border-color: coral;
  transition: 0.4s;
}

/* ------------------------------------------------------------------------- */

.face {
  width: 100%;
  height: 200px;
  background-size: contain;
}

/* ------------------------------------------------------------------------- */

.caption {
  width: calc(100% - 20px);
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  line-height: 150%;
  text-align: center;
  white-space: pre;
}

.caption > div {
  text-align: center;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.title {
  font-size: 0.75rem;
  background-color: rgb(22, 21, 21);
  padding: 0px !important;
  margin-block: 0.5px;
  border-radius: 10px;
  color: white;
  text-align: center;
}
/* 
.title-dark {
  background-color: rgb(22, 21, 21);
}
.title-light {
  color: white;
} */

.divider {
  width: 80% !important;
  margin-inline: 10%;
  margin-bottom: 30px;
  height: 2px;
  background: white;
  outline: none;
  border: none;
}
