.card {
  position: relative;
}

.jump {
  position: absolute;
  height: 1rem;
  width: 1.2rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  top: 40px;
  right: min(40px, 10%);
  text-decoration: none;
  font-weight: bold;
  transition: all 1s ease;
  opacity: 0.5;
}

.jump-dark {
  border: 2px solid white;
  color: white;
}

.jump-light {
  border: 2px solid black;
  color: black;
}

.jump-dark:hover {
  opacity: 1;
  border-color: orange;
  color: orange;
}

.jump-light:hover {
  opacity: 1;
  border-color: coral;
  color: coral;
}

@media screen and (max-width: 960px) {
  .jump {
    position: absolute;
    font-size: 1rem;
    height: 0.8rem;
    width: 0.6rem;
    top: 20px;
    right: 20px;
  }
}