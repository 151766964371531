.name-card {
  height: -moz-max-content;
  height: fit-content;
  margin-left: 20px;
  margin-right: 20px;
  /* padding-bottom: 10px; */
  width: 200px;
  /* min-height: 250px; */
  margin-top: 0px;
  border: 2px solid;
  border-radius: 10px;
  /* line-height: 25px; */
  display: block;
  /* float: left; */
  margin-bottom: 30px;
  overflow: auto;
  filter: opacity(0.8);
  transition: 0.4s;
}

.name-card:hover {
  filter: opacity(1);
}

.name-card-dark:hover {
  border-color: orange;
  transition: 0.4s;
}

.name-card-light:hover {
  border-color: coral;
  transition: 0.4s;
}

.caption {
  width: calc(100% - 20px) !important;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  line-height: 150%;
  text-align: left;
  white-space: pre;
  display: block;
  overflow-x: auto;
}

.name {
  /* text-overflow: ellipsis; */
  overflow-x: hidden;
  white-space: nowrap;
  overflow-wrap: break-word;
  /* word-break: break-all; */
}
.caption > .roles {
  width: 100%;
  display: inline-block;
  font-size: 12px;
}
